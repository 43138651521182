import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonDataService } from '../core/services/common-data.service';
import Quagga from '@ericblade/quagga2';
import QrCodeReader from '@ericblade/quagga2-reader-qr';
import { UrlsService } from '../core/services/urls.service';
import {MatSnackBar} from '@angular/material/snack-bar';
Quagga.registerReader('qrcode', QrCodeReader);

@Component({
  selector: 'app-user-instance',
  templateUrl: './user-instance.component.html',
  styleUrls: ['./user-instance.component.scss']
})
export class UserInstanceComponent implements OnInit,OnDestroy {

  public errorMessage: string;
  public userInstanceForm: FormGroup;
  urlstring:any
  // const Quagga = require('@ericblade/quagga2').default;
  
  constructor(public router: Router,public fb: FormBuilder,public commonDataService: CommonDataService,private urlservice:UrlsService, private _snackBar: MatSnackBar) {
    this.errorMessage = null;
    this.urlstring =  this.urlservice
    this.userInstanceForm = this.fb.group({
      userInstance: [null, Validators.required]
    });
   }

  ngOnInit() {
    return Quagga.init({
      inputStream: {
        constraints: {
          facingMode: 'environment' // restrict camera type
        },
        area: { // defines rectangle of the detection
          top: '40%',    // top offset
          right: '0%',  // right offset
          left: '0%',   // left offset
          bottom: '2%'  // bottom offset
        },
      },
      decoder: {
        readers: ['code_128_reader', 'qrcode'] // restrict code types
      },
    },
    (err) => {
      if (err) {
        console.log(err)
        this.errorMessage = `QuaggaJS could not be initialized, err: ${err}`;
      } else {
        Quagga.start();
        Quagga.onDetected((res) => {
          this.submit(res.codeResult.code)
        })
      }
    });
  }

  ngOnDestroy(): void {
    Quagga.stop();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  submit(instance) {
    this.errorMessage = null;
    console.log(instance)
    console.log(instance.includes("teamium.net"))
    if(instance.includes("teamium.net")){
      this.urlservice.urlString=instance;
      this.commonDataService.setUserInstance(instance)
      this.goToSignin();
    }
    else {
      this.commonDataService.showSnackBar('Please scan a valid qrcode','warning')
    }    
  }

  goDashboard() {
    this.router.navigate(['/teamium']);
  }

  goToSignin(){
    console.log(this.urlstring.urlString)
    try {
    this.router.navigate(['/signin']);
      
    } catch (error) {
      console.log(error)
    }
  }

  formValid() {
    if (this.userInstanceForm.valid) {
      return true;
    }
    this.errorMessage = null;
    return false;
  }

}
