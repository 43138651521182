import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  HOST: string;
  PORT: string;
  public INSTANCES:any;
  constructor() { 
    // this.HOST = '52.206.76.204';
    // this.PORT = '80';
	// this.HOST = 'localhost';
	//this.PORT = '8180';


    this.INSTANCES = {
		'local':{HOST:'localhost',PORT:'8080'},
     // 'local':{HOST:'192.168.1.220',PORT:'8180'},
      //'uspft':{HOST:'52.206.76.204',PORT:'81'},
      //'canal9':{HOST:'18.185.72.112',PORT:''},
      //'mumbai':{HOST:'13.235.223.186:443',PORT:'8180'},
    }

    //13.235.223.186


    // this.HOST = '192.168.1.220';
    // this.PORT = '8180';
    // this.HOST = '192.168.1.183';
    // this.PORT = '8180';
  }
}