import { Injectable } from '@angular/core';
import { ConfigService } from './config.service'
import { CommonDataService } from './common-data.service';
@Injectable({
  providedIn: 'root'
})
export class UrlsService {
  urlObject: any;
  urlString: any;

  constructor(configService: ConfigService,public commonDataService: CommonDataService) {
    this.urlString =  this.commonDataService.getUserInstance();
    console.log(this.urlString)
  
    
    this.urlObject = {
      'login': {
        'method': 'POST',
        'url':  'oauth/token'
      },
      'getstatus': {
        'method': 'GET',
        'url':  'user/login-status'
      },
      'getLoggedInUser': {
        'method': 'GET',
        'url':  'staff/logged-in-user'
      },
      "getBookingsByLoggedInUserUnassign": {
        'method': 'GET',
        'url':  'booking'
      },
      "getBookingsByLoggedInUser": {
        'method': 'GET',
        'url':  'booking/by-logged-in-user/'
      },
      "assignJobToLoggedInUser": {
        'method': 'POST',
        'url':  'event/assign/to/logged-in-user'
      },
      "unAssignJobToLoggedInUser": {
        'method': 'POST',
        'url':  'booking/unassign/resource/'
      },
      "getTaskDetails": {
        'method': 'POST',
        'url':  'booking'
      },
      "startOrEndUserBookingTime": {
        'method': 'POST',
        'url':  'booking/add/start-or-end-time/'
      },
      "changeCompletionTimeOnBooking": {
        'method': 'POST',
        'url':  'booking/change-completion/'
      },
      "getProfileDetails":{
        'method': 'GET',
        'url':  'staff'
      },
      "getStaffDropdownData":{
        'method': 'GET',
        'url':  'staff/dropdown'
      },
      'saveProfileDetails': {
        'method': 'POST',
        'url':  'staff'
      },
      "findUserBookingByUser": {
        'method': 'GET',
        'url':  'user-booking'
      },
      'getRecentProject': {
        'method': 'GET',
        'url': '/budget/non-archived/recent/0'
      },
      'getEeventByUserIdForMobile': {
        'method': 'GET',
        'url': 'user-booking/for-mobile'
      },
      "addLineToProject": {
        'method': 'POST',
        'url': 'project/line/'
      },
      'createProjectBooking': {
        'method': 'POST',
        'url': 'project/booking'
      },
      "saveProject": {
        'method': 'POST',
        'url': 'project'
      },
      //ratecard
      'getRatesByFunction': {
        'method': 'GET',
        'url': 'rate/function/'
      },
      "saveOrUpdateEvent": {
        'method': 'POST',
        'url': 'event'
      },
      "operatorList":{
        'method':'POST',
        'url': 'booking/add-to-operator/'
      },
      "removeOperatorList":{
        'method':'POST',
        'url': 'booking/remove-operator/'
      },
      "addGroupOnBooking": {
        'method': 'POST',
        'url': 'booking/add-to-group/'
      },
      "removeGroupOnBooking": {
        'method': 'POST',
        'url': 'booking/remove-group/'
      },
      'notifyAll': {
        'method': 'POST',
        'url': 'notification/notify-all'
      },
      "getProjectContactsById":{
        'method':'GET',
        'url': 'project/contacts'
      },
      'updateFeeStatus': {
        'method': 'POST',
        'url': 'project/add-project-fees/'
      },
      'getAllProjectFeesPerFunctionByRecordId': {
        'method': 'GET',
        'url': 'project/project-fees-per-function-per-record/'
      },
      "getProjectBasicDetails":{
        'method':'GET',
        'url': 'project/project-baisc-details'
      },
      "getAllProjectExtraList":{
        'method':'GET',
        'url': 'configuration/project-extra'
      },
      'getProductionGroups': {
        'method': 'GET',
        'url': 'configuration/production-group'
      },
      'getAvailablePersonals': {
        'method': 'GET',
        'url': 'staff/available'
      },
      'saveActual': {
        'method': 'POST',
        'url': 'project/line/saveActual'
      },
      'saveLineBilling': {
        'method': 'POST',
        'url': 'project/line/saveBilling'
      },
      'saveNewTemplateToBooking': {
        'method': 'POST',
        'url': 'project/template/'
      },
      "getCurrencyList":{
        'method':'GET',
        'url': 'configuration/currency'        
      },
      "saveCurrency":{
        'method':'POST',
        'url': 'configuration/currency'        
      },
      'getAllPhaseBookings': {
        'method': 'GET',
        'url': 'configuration/different-booking-phase'
      },
      "getActiveCurrencyList":{
        'method':'GET',
        'url': 'configuration/currency/status'        
      },
      'deleteLineFromBokking': {
        'method': 'DELETE',
        'url': 'project/line/'
      },
      "addTemplateToBooking": {
        'method': 'POST',
        'url': '/project/apply/template/'
      },
      'findBudgetIdFromProjectId': {
        'method': 'GET',
        'url': 'project/budget-id'
      },
      'getProjectDropdown': {
        'method': 'GET',
        'url': 'budget/dropdown'
      },
      'getProjectBudget': {
        'method': 'GET',
        'url': 'budget/getProjectBudget'
      },
      'getSettings': {
        'method': 'GET',
        'url': 'configuration/settings'
      },
      "getProjectDropdownForFunctionCatalog": {
        'method': 'GET',
        'url': 'budget/dropdown/function-catalog'
      },
      'saveProjectBudget': {
        'method': 'POST',
        'url': 'budget/saveProjectBudget'
      },
      'getBookingByBookingId': {
        'method': 'GET',
        'url': 'project/'
      },
      'getGroups': {
        'method': 'GET',
        'url': 'configuration/group'
      },
      "getAllPersonnelList":{
        'method':'GET',
        'url': 'staff/getStaffmemberForDropDown'
      },
      'getFunctionList': {
        'method': 'GET',
        'url': 'function/roots'
      },
      "findAllProjectTodoOrProgress": {
        'method': 'GET',
        'url':  'project/progress'
      },
      'getProjectsByStatus': {
        'method': 'GET',
        'url' : 'budget/status'
      },
      'getProjectDescriptionDate': {
        'method': 'GET',
        'url': '/budget/description/'
      },
      'getRecentViewedProjects': {
        'method': 'GET',
        'url': 'budget/recent/0'
      },
      'getClientNameForProject': {
        'method': 'GET',
        'url': '/budget/non-archived/client/'
      },
      'getProjectByBudget': {
        'method': 'GET',
        'url': 'project/booking'
      },
      'getUnassignedBookings': {
        'method': 'GET',
        'url': '/budget/non-archived/bookings'
      },
      "getMilestonesByProjectId": {
        'method': 'GET',
        'url': 'budget/milestone/'
      },
      "getProjectIdByQuotationId":{
        'method':'GET',
        'url': 'project/get-projectId-from-quotationId'
      },
      'changesProjectStatus': {
        'method': 'Post',
        'url': '/budget/change/status'
      } ,
      "saveBookingFromUserScheduler": {
        'method': 'POST',
        'url':  'user-booking/time-report'
      }, 
      "saveOrUpdateUserBooking": {
        'method': 'POST',
        'url':  'user-booking'
      },
      "deleteUserBooking": {
        'method': 'DELETE',
        'url':  'user-booking/time-report/'
      }, 
      "deleteDuty": {
        'method': 'DELETE',
        'url':  'user-booking'
      }, 
      'logout': {
        'method': 'POST',
        'url':  'user/logout'
      },
      'getEquipments': {
        'method': 'GET',
        'url':'equipment'
      },
      'getEquipmentById': {
        'method': 'GET',
        'url':'equipment/'
      },
      'getEquipmentForPackage': {
        'method': 'GET',
        'url':'package/equipment/'
      },
      'savePackage': {
        'method': 'POST',
        'url':'package/'
      },
      'getWorkOrder': {
        'method': 'GET',
        'url':'booking/getWorkOrderByBooking/'
  
      }, 'getTravelOrder': {
        'method': 'GET',
        'url':'booking/getTravelOrderByBooking/'
  
      }, 'getMediaOrder': {
        'method': 'GET',
        'url':'booking/getMediaOrderByBooking/'  
      },  
      'saveOrderForm': {
        'method': 'POST',
        'url':'booking/add/order-form/'
  
      },
      'findNonArchivedProjects': {
        'method': 'GET',
        'url': '/budget/status/non-archived'
      },
      "getLoggedInUserNotificationList":{
        'method': 'GET',
        'url': 'notification/app-notification/'
      },
      "loggedInUser": {
        'method': 'GET',
        'url': 'staff/logged-in-user'
      },
      'notificationCount':{
        'method': 'GET',
        'url':  'notification/app-notification/count'
      },
      "updateNotificationStatus":{
        'method': 'POST',
        'url': 'notification/app-notification-status/'
      },
      "deleteNotificationById":{
        'method': 'DELETE',
        'url': 'notification/app-notification/'
      },
      'getProjectBookingStatus': {
        'method': 'GET',
        'url': 'budget/booking-status/'
      },
      "getQuotationBasicDetails":{
        'method':'GET',
        'url': 'budget/project-baisc-details'
      },
      'getCallSheetDetailsByByBookingId': {
        'method': 'GET',
        'url': 'project/call-sheet/'
      },'saveCallSheetDetails': {
        'method': 'POST',
        'url': 'project/call-sheet'
      },
      "getProjectResources": {
        'method': 'GET',
        'url': 'booking/function/by-project'
      },
      "getProjectsEvent": {
        'method': 'GET',
        'url': 'event/by-project'
      },
      'getAllBookingEventStatus': {
        'method': 'GET',
        'url': 'event/booking-event-status'
      },
      'getAllProjectSchedulerView': {
        'method': 'GET',
        'url': 'configuration/project-schedule-timeline-view'
      },
      'getShowListForScheduler': {
        'method': 'GET',
        'url': 'budget/scheduler/shows'
      },
      'getAllAvailableFunctions': {
        'method': 'GET',
        'url': 'resource/functions/available'
      },
      'getProjectPlanningScheduler': {
        'method': 'GET',
        'url': 'project-planning/scheduler/planning-events/'
      },
      "getColors": {
        'method': 'GET',
        'url': 'configuration/color'
      },
      'projectBasicInfo': {
        'method': 'GET',
        'url': 'project/info'
      },
      'getShowsByStatus': {
        'method': 'GET',
        'url': 'show/status'
      },
      'getDropDownClientist': {
        'method': 'GET',
        'url': 'client/getClientDropDownList'
      },
      "findUserLeaveRecordByStaffId": {
        'method': 'GET',
        'url': '/user-leave-record'
      },
      'getAllRosterDuties': {
        'method': 'GET',
        'url': 'configuration/roster-duty'
      },
      "saveOrUpdateUserLeaveRequest": {
        'method': 'POST',
        'url': '/user-leave-record/leave-request'
      },
    }
  }
}
